<template>
  <div>
    <v-row justify="end">
      <template>
        <div class="my-2">
          <v-btn class="btn-primary" fab small dark @click="OpenModalAdd()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </template>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="listVillages"
      :items-per-page="15"
      class="elevation-1"
      :loading="TableLoading"
      :disabled="TableLoading"
      loading-text="Loading... Please wait"
    >
      <!--Action -->

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="OpenModalEdit(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item.id)"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <!--Delete Modal-->
    <ModalAdd>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span class="headline">Add Village</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <!--     <v-col cols="12">
                  <v-select
                      required
                      v-model="selectedProvince"
                      :items="listProvinces"
                      item-text="name"
                      item-value="id"
                      label="ເລືອກແຂວງ*"
                      :rules="ruleDistrict"
                    >
                    </v-select>
                  </v-col> -->

                  <v-col cols="12">
                    <v-select
                      required
                      v-model="selectedDistrict"
                      :items="listDistricts"
                      item-text="name"
                      item-value="id"
                      label="ເລືອກເມືອງ*"
                      :rules="ruleDistrict"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Name*"
                      required
                      v-model="village.name"
                      :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="CloseModalAdd()">
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="AddItem()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ModalAdd>

    <!--Edit Modal-->
    <ModalEdit>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span class="headline">Update Village</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      required
                      v-model="village_edit.district_id"
                      :items="listDistricts"
                      item-text="name"
                      item-value="id"
                      label="ເລືອກເມືອງ*"
                      :rules="ruleDistrict"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Name*"
                      required
                      v-model="village_edit.name"
                      :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="CloseModalEdit()">
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="UpdateItem()"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ModalEdit>
    <!--Delete Modal-->
    <ModalDelete>
      <template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="loading"
            :disabled="loading"
            @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
    </ModalDelete>
  </div>
</template>

<script>
import manage_village from "@/mixins/manage_village";
export default {
  mixins: [manage_village],
  metaInfo() {
    return {
      title: `ຂໍ້ມູນບ້ານ`,
      meta: [
        { name: 'description', content: 'ຂໍ້ມູນບ້ານ'},
      ]
    }
  },
};
</script>
<style>
@import "../../../public/scss/main.scss";
</style>